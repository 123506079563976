/*@font-face {*/
/*	font-family: "Adjusted Trebuchet MS Fallback";*/
/*	src: local(Trebuchet MS);*/
/*	size-adjust: 108%;*/
/*	ascent-override: 90%;*/
/*	descent-override: 21%;*/
/*	line-gap-override: 24%;*/
/*}*/

@font-face {
	font-display: swap;
	font-family: 'n27regular';
	font-style: normal;
	font-weight: 100;
	src: url('../../static/fonts/n27/n27-regular-webfont.woff2') format('woff2');
}
